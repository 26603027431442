import styled from 'styled-components'

const AppIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 41px;
  height: 41px;
  background: white;
  border: 0.5px solid ${(props) => props.theme.surface3};
  border-radius: 8px;
`
export function MobileAppLogo() {
  return (
    <AppIcon>
      <svg xmlns="http://www.w3.org/2000/svg" width="31" height="32" viewBox="0 0 31 32" fill="none">
        <path
          d="M24.7192 4.90121C24.7622 4.14836 24.8647 3.65178 25.0709 3.1983C25.1525 3.01879 25.229 2.87188 25.2408 2.87188C25.2526 2.87188 25.2171 3.00438 25.1619 3.16628C25.012 3.6064 24.9874 4.20836 25.0907 4.90873C25.2217 5.79737 25.2963 5.92558 26.2397 6.88551C26.6822 7.33576 27.1969 7.90362 27.3834 8.14742L27.7227 8.59072L27.3834 8.27391C26.9685 7.88649 26.0144 7.13093 25.8036 7.02292C25.6623 6.95048 25.6413 6.95174 25.5541 7.03812C25.4738 7.11771 25.4569 7.23731 25.4457 7.80272C25.4284 8.68394 25.3077 9.24956 25.0166 9.81513C24.8591 10.121 24.8343 10.0557 24.9768 9.71046C25.0832 9.45267 25.094 9.33934 25.0932 8.48627C25.0916 6.77223 24.8872 6.36016 23.6885 5.65426C23.3849 5.47544 22.8845 5.21754 22.5767 5.08113C22.2688 4.94471 22.0243 4.82589 22.0332 4.81702C22.0672 4.78338 23.2361 5.12306 23.7066 5.30325C24.4065 5.57127 24.522 5.60601 24.607 5.57368C24.664 5.552 24.6915 5.38681 24.7192 4.90121Z"
          fill="url(#paint0_linear_3063_12011)"
        />
        <path
          d="M9.93229 2.40762C9.51711 2.34362 9.49961 2.33611 9.69498 2.30628C10.0694 2.24906 10.9535 2.32704 11.5627 2.471C12.985 2.80695 14.2792 3.66758 15.6607 5.19604L16.0277 5.6021L16.5527 5.51823C18.7646 5.16501 21.0148 5.44573 22.8968 6.30969C23.4146 6.54738 24.2309 7.02051 24.3329 7.1421C24.3654 7.18085 24.4251 7.43028 24.4655 7.69644C24.6053 8.61726 24.5353 9.32308 24.2519 9.85025C24.0976 10.1371 24.089 10.228 24.1927 10.4735C24.2755 10.6695 24.5063 10.8145 24.7348 10.8141C25.2025 10.8135 25.7059 10.0622 25.9391 9.01685L26.0318 8.60161L26.2153 8.80821C27.2221 9.94168 28.0128 11.4875 28.1487 12.5878L28.1841 12.8746L28.0148 12.6138C27.7236 12.1651 27.431 11.8596 27.0562 11.6132C26.3808 11.169 25.6665 11.0179 23.775 10.9188C22.0667 10.8294 21.0998 10.6844 20.1411 10.3737C18.5099 9.84524 17.6876 9.14141 15.75 6.61524C14.8894 5.49319 14.3575 4.8724 13.8283 4.37245C12.626 3.23648 11.4446 2.64072 9.93229 2.40762Z"
          fill="url(#paint1_linear_3063_12011)"
        />
        <path
          d="M10.7388 7.84129C9.8959 6.68821 9.37441 4.92028 9.48732 3.59867L9.52223 3.18969L9.71408 3.22448C10.0744 3.28979 10.6956 3.51955 10.9864 3.69512C11.7847 4.17686 12.1302 4.81111 12.4818 6.43978C12.5848 6.91682 12.7199 7.45667 12.7821 7.63943C12.8822 7.93361 13.2604 8.62076 13.5679 9.06703C13.7894 9.38843 13.6422 9.54074 13.1527 9.49682C12.4049 9.42974 11.3921 8.73514 10.7388 7.84129Z"
          fill="url(#paint2_linear_3063_12011)"
        />
        <path
          d="M23.6959 16.4231C19.757 14.8471 18.3698 13.4792 18.3698 11.1711C18.3698 10.8315 18.3815 10.5536 18.3959 10.5536C18.4102 10.5536 18.5626 10.6657 18.7345 10.8027C19.5332 11.4393 20.4277 11.7112 22.9037 12.0701C24.3608 12.2814 25.1807 12.452 25.9371 12.7013C28.3412 13.4936 29.8286 15.1014 30.1833 17.2914C30.2864 17.9278 30.226 19.1211 30.0589 19.75C29.9269 20.2468 29.5243 21.1422 29.4176 21.1765C29.388 21.1861 29.3589 21.0733 29.3513 20.9199C29.3108 20.0976 28.8927 19.297 28.1903 18.6973C27.3918 18.0154 26.319 17.4726 23.6959 16.4231Z"
          fill="url(#paint3_linear_3063_12011)"
        />
        <path
          d="M20.9307 17.0778C20.8813 16.7859 20.7957 16.4133 20.7405 16.2496L20.64 15.952L20.8266 16.1602C21.085 16.4484 21.2891 16.8171 21.4621 17.3082C21.5942 17.683 21.6091 17.7944 21.608 18.4035C21.607 19.0014 21.5905 19.1268 21.4686 19.4642C21.2764 19.9961 21.0377 20.3733 20.6373 20.7782C19.9178 21.5059 18.9928 21.9088 17.6578 22.0759C17.4258 22.1049 16.7495 22.1538 16.1549 22.1846C14.6565 22.262 13.6704 22.422 12.7842 22.7312C12.6568 22.7757 12.543 22.8027 12.5315 22.7912C12.4956 22.7558 13.099 22.3984 13.5973 22.1598C14.3 21.8235 14.9995 21.6399 16.5667 21.3806C17.3409 21.2524 18.1404 21.097 18.3434 21.0351C20.2606 20.451 21.2461 18.9436 20.9307 17.0778Z"
          fill="url(#paint4_linear_3063_12011)"
        />
        <path
          d="M22.7363 20.2643C22.213 19.1464 22.0928 18.067 22.3795 17.0604C22.4102 16.9528 22.4595 16.8648 22.4892 16.8648C22.5188 16.8648 22.6423 16.9311 22.7636 17.0122C23.0047 17.1735 23.4884 17.4452 24.777 18.1434C26.3849 19.0145 27.3017 19.6891 27.9251 20.4598C28.4711 21.1348 28.809 21.9035 28.9716 22.8409C29.0637 23.3719 29.0098 24.6495 28.8727 25.1842C28.4403 26.8701 27.4355 28.1943 26.0024 28.9671C25.7925 29.0802 25.604 29.1732 25.5836 29.1736C25.5632 29.174 25.6397 28.9807 25.7536 28.744C26.2357 27.7426 26.2906 26.7685 25.9261 25.6842C25.7029 25.0203 25.2479 24.2102 24.3291 22.8411C23.261 21.2493 22.9991 20.8257 22.7363 20.2643Z"
          fill="url(#paint5_linear_3063_12011)"
        />
        <path
          d="M7.94152 26.2961C9.40321 25.0697 11.2219 24.1985 12.8785 23.931C13.5925 23.8158 14.7819 23.8615 15.443 24.0297C16.5028 24.2992 17.4508 24.9028 17.9438 25.622C18.4256 26.3249 18.6323 26.9374 18.8475 28.3002C18.9324 28.8378 19.0248 29.3777 19.0527 29.4999C19.2143 30.2061 19.5289 30.7707 19.9186 31.0543C20.5377 31.5045 21.6036 31.5325 22.6522 31.126C22.8302 31.057 22.9847 31.0093 22.9956 31.0201C23.0336 31.0576 22.5056 31.4089 22.1331 31.5938C21.6318 31.8426 21.2333 31.9388 20.7037 31.9388C19.7433 31.9388 18.9459 31.4533 18.2806 30.4633C18.1497 30.2685 17.8554 29.685 17.6266 29.1666C16.9242 27.5745 16.5773 27.0894 15.7616 26.5586C15.0518 26.0967 14.1364 26.014 13.4478 26.3496C12.5432 26.7903 12.2908 27.9391 12.9387 28.667C13.1962 28.9564 13.6764 29.2059 14.069 29.2545C14.8036 29.3453 15.4349 28.7899 15.4349 28.0529C15.4349 27.5744 15.2498 27.3013 14.7838 27.0924C14.1474 26.807 13.4634 27.1406 13.4666 27.7346C13.4681 27.988 13.5791 28.1471 13.8346 28.262C13.9986 28.3357 14.0024 28.3415 13.8687 28.3139C13.2848 28.1936 13.148 27.4941 13.6175 27.0297C14.1811 26.4722 15.3467 26.7182 15.747 27.4792C15.9151 27.7988 15.9346 28.4354 15.788 28.8198C15.4599 29.6801 14.5032 30.1326 13.5327 29.8864C12.872 29.7187 12.6029 29.5372 11.8063 28.7218C10.4219 27.3047 9.88451 27.0302 7.88879 26.7206L7.50636 26.6613L7.94152 26.2961Z"
          fill="url(#paint6_linear_3063_12011)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.4461 0.934376C6.06909 6.51007 9.25318 8.81051 9.60703 9.29653C9.89917 9.69784 9.78922 10.0586 9.28872 10.3414C9.01039 10.4986 8.43817 10.6579 8.15167 10.6579C7.82762 10.6579 7.71636 10.5337 7.71636 10.5337C7.52847 10.3566 7.42265 10.3875 6.45778 8.68561C5.11822 6.62011 3.99719 4.90668 3.96661 4.87799C3.89589 4.81165 3.89711 4.81389 6.32119 9.12395C6.71285 10.0223 6.3991 10.352 6.3991 10.48C6.3991 10.7402 6.32764 10.8771 6.00454 11.2352C5.46591 11.8323 5.22513 12.5032 5.05132 13.8917C4.85647 15.4483 4.30856 16.5478 2.79013 18.4296C1.90129 19.5311 1.75585 19.733 1.53158 20.177C1.24908 20.7361 1.1714 21.0493 1.13992 21.7553C1.10664 22.5017 1.17145 22.9839 1.40095 23.6976C1.60187 24.3225 1.81159 24.735 2.34774 25.5602C2.81043 26.2724 3.07684 26.8016 3.07684 27.0086C3.07684 27.1733 3.10849 27.1735 3.82546 27.0127C5.54127 26.6276 6.93451 25.9504 7.71808 25.1206C8.20303 24.6069 8.31688 24.3233 8.32057 23.6194C8.323 23.159 8.3067 23.0626 8.18146 22.7977C7.97759 22.3666 7.60645 22.0082 6.78844 21.4525C5.71662 20.7244 5.25883 20.1383 5.13237 19.3321C5.02864 18.6707 5.14898 18.204 5.74192 16.9691C6.35564 15.6909 6.50773 15.1462 6.61061 13.8577C6.67706 13.0253 6.76908 12.697 7.00976 12.4335C7.26077 12.1588 7.48674 12.0657 8.10795 11.9814C9.12071 11.8439 9.76559 11.5835 10.2957 11.0981C10.7555 10.677 10.9479 10.2713 10.9775 9.66049L10.9999 9.19753L10.7429 8.89981C9.81232 7.82157 0.822882 0.086792 0.765613 0.086792C0.753379 0.086792 1.05961 0.468231 1.4461 0.934376ZM3.59923 22.5999C3.80964 22.2296 3.69784 21.7536 3.3459 21.521C3.01336 21.3013 2.49677 21.4048 2.49677 21.6911C2.49677 21.7785 2.54539 21.8421 2.65497 21.8982C2.83949 21.9925 2.85288 22.0986 2.70771 22.3154C2.56068 22.535 2.57255 22.7281 2.74119 22.8593C3.01298 23.0708 3.39773 22.9545 3.59923 22.5999Z"
          fill="url(#paint7_linear_3063_12011)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.639 12.2242C11.1636 12.3693 10.7014 12.87 10.5583 13.3951C10.4711 13.7154 10.5206 14.2772 10.6513 14.4508C10.8625 14.731 11.0668 14.8049 11.6198 14.801C12.7025 14.7935 13.6438 14.3321 13.7532 13.7552C13.8429 13.2823 13.4295 12.627 12.8601 12.3393C12.5663 12.1909 11.9414 12.132 11.639 12.2242ZM12.9048 13.2077C13.0717 12.972 12.9987 12.7172 12.7147 12.5448C12.1739 12.2167 11.3561 12.4882 11.3561 12.9959C11.3561 13.2486 11.7826 13.5243 12.1735 13.5243C12.4337 13.5243 12.7897 13.3701 12.9048 13.2077Z"
          fill="url(#paint8_linear_3063_12011)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_3063_12011"
            x1="28.7526"
            y1="26.953"
            x2="10.033"
            y2="11.0335"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FC72FF" />
            <stop offset="1" stopColor="#FF41F4" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_3063_12011"
            x1="28.7526"
            y1="26.953"
            x2="10.033"
            y2="11.0335"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FC72FF" />
            <stop offset="1" stopColor="#FF41F4" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_3063_12011"
            x1="28.7526"
            y1="26.953"
            x2="10.033"
            y2="11.0335"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FC72FF" />
            <stop offset="1" stopColor="#FF41F4" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_3063_12011"
            x1="28.7526"
            y1="26.953"
            x2="10.033"
            y2="11.0335"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FC72FF" />
            <stop offset="1" stopColor="#FF41F4" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_3063_12011"
            x1="28.7526"
            y1="26.953"
            x2="10.033"
            y2="11.0335"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FC72FF" />
            <stop offset="1" stopColor="#FF41F4" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_3063_12011"
            x1="28.7526"
            y1="26.953"
            x2="10.033"
            y2="11.0335"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FC72FF" />
            <stop offset="1" stopColor="#FF41F4" />
          </linearGradient>
          <linearGradient
            id="paint6_linear_3063_12011"
            x1="28.7526"
            y1="26.953"
            x2="10.033"
            y2="11.0335"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FC72FF" />
            <stop offset="1" stopColor="#FF41F4" />
          </linearGradient>
          <linearGradient
            id="paint7_linear_3063_12011"
            x1="28.7526"
            y1="26.953"
            x2="10.033"
            y2="11.0335"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FC72FF" />
            <stop offset="1" stopColor="#FF41F4" />
          </linearGradient>
          <linearGradient
            id="paint8_linear_3063_12011"
            x1="28.7526"
            y1="26.953"
            x2="10.033"
            y2="11.0335"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FC72FF" />
            <stop offset="1" stopColor="#FF41F4" />
          </linearGradient>
        </defs>
      </svg>
    </AppIcon>
  )
}
