import { Trans } from '@lingui/macro'
import { PAGE_SIZE, useTopTokens } from 'graphql/data/TopTokens'
import { validateUrlChainParam } from 'graphql/data/util'
import { ReactNode } from 'react'
import { AlertTriangle } from 'react-feather'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import { MAX_WIDTH_MEDIA_BREAKPOINT } from '../constants'
import { HeaderRow, LoadedRow, LoadingRow } from './TokenRow'

const GridContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${MAX_WIDTH_MEDIA_BREAKPOINT};
  background-color: ${({ theme }) => theme.surface1};

  margin-left: auto;
  margin-right: auto;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.surface3};
`

const TokenDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: 100%;
  width: 100%;
`

const NoTokenDisplay = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 60px;
  color: ${({ theme }) => theme.neutral2};
  font-size: 16px;
  font-weight: 535;
  align-items: center;
  padding: 0px 28px;
  gap: 8px;
`

function NoTokensState({ message }: { message: ReactNode }) {
  return (
    <GridContainer>
      <HeaderRow />
      <NoTokenDisplay>{message}</NoTokenDisplay>
    </GridContainer>
  )
}

const LoadingRows = ({ rowCount }: { rowCount: number }) => (
  <>
    {Array(rowCount)
      .fill(null)
      .map((_, index) => {
        return <LoadingRow key={index} first={index === 0} last={index === rowCount - 1} />
      })}
  </>
)

function LoadingTokenTable({ rowCount = PAGE_SIZE }: { rowCount?: number }) {
  return (
    <GridContainer>
      <HeaderRow />
      <TokenDataContainer>
        <LoadingRows rowCount={rowCount} />
      </TokenDataContainer>
    </GridContainer>
  )
}

export default function OldTokenTable() {
  const chainName = validateUrlChainParam(useParams<{ chainName?: string }>().chainName)
  // const { tokens, tokenSortRank, loadingTokens, sparklines } = useTopTokens(chainName)

  /* loading and error state */
  // if (loadingTokens && !tokens) {
  //   return <LoadingTokenTable rowCount={PAGE_SIZE} />
  // } else if (!tokens) {
  //   return (
  //     <NoTokensState
  //       message={
  //         <>
  //           <AlertTriangle size={16} />
  //           <Trans>An error occurred loading tokens. Please try again.</Trans>
  //         </>
  //       }
  //     />
  //   )
  // } else if (tokens?.length === 0) {
  //   return <NoTokensState message={<Trans>No tokens found</Trans>} />
  // } else {
  //   return (
  //     <GridContainer>
  //       <HeaderRow />
  //       <TokenDataContainer>
  //         {tokens.map(
  //           (token, index) =>
  //             token?.address && (
  //               <LoadedRow
  //                 key={token.address}
  //                 tokenListIndex={index}
  //                 tokenListLength={tokens.length}
  //                 token={token}
  //                 sparklineMap={sparklines}
  //                 sortRank={tokenSortRank[token.address]}
  //               />
  //             )
  //         )}
  //       </TokenDataContainer>
  //     </GridContainer>
  //   )
  // }

  return <>Working on it</>
}
