

export const FARM_LIST = "/incentives/list";
export const MY_FARM_LIST = "/incentives/myFarm";
export const UPDATE_MY_FARM = "/incentives/updateMyFarm";
export const DELETED_FARM_LIST = "/incentives/deleted";


export const UPDATE_FARM = "/incentives/update";
export const GET_STAKING_TRANSACTION_LIST = "/trxn/stakingTrxn";

export const NFT_LIST = "/nft/ownerNft";











